export const   PRODUCT_OUT_MATERIAL_FETCH_LIST = "PRODUCT_OUT_MATERIAL_FETCH_LIST";
export const   PRODUCT_OUT_MATERIAL_FETCH_LIST_SUCCESS = "PRODUCT_OUT_MATERIAL_FETCH_LIST_SUCCESS";

export const   PRODUCT_OUT_MATERIAL_FETCH_ITEM_BY_ID = "PRODUCT_OUT_MATERIAL_FETCH_ITEM_BY_ID";
export const   PRODUCT_OUT_MATERIAL_FETCH_ITEM_BY_ID_SUCCESS = "PRODUCT_OUT_MATERIAL_FETCH_ITEM_BY_ID_SUCCESS";

export const   PRODUCT_OUT_MATERIAL_CREATE_UPDATE = "PRODUCT_OUT_MATERIAL_CREATE_UPDATE";
export const   PRODUCT_OUT_MATERIAL_CREATE_UPDATE_SUCCESS = "PRODUCT_OUT_MATERIAL_CREATE_UPDATE_SUCCESS";

export const   PRODUCT_OUT_MATERIAL_DELETE = "PRODUCT_OUT_MATERIAL_DELETE";
export const   PRODUCT_OUT_MATERIAL_DELETE_SUCCESS = "PRODUCT_OUT_MATERIAL_DELETE_SUCCESS";
export const   PRODUCT_OUT_MATERIAL_CONFIRM = "PRODUCT_OUT_MATERIAL_CONFIRM";
export const   PRODUCT_OUT_MATERIAL_CONFIRM_SUCCESS = "PRODUCT_OUT_MATERIAL_CONFIRM_SUCCESS";
export const   PRODUCT_OUT_MATERIAL_UPDATE = "PRODUCT_OUT_MATERIAL_UPDATE";
export const   PRODUCT_OUT_MATERIAL_UPDATE_SUCCESS = "PRODUCT_OUT_MATERIAL_UPDATE_SUCCESS";

export const   PRODUCT_OUT_MATERIAL_FETCH_BY_TYPE = "PRODUCT_OUT_MATERIAL_FETCH_BY_TYPE";
export const   PRODUCT_OUT_MATERIAL_FETCH_BY_TYPE_SUCCESS = "PRODUCT_OUT_MATERIAL_FETCH_BY_TYPE_SUCCESS";


export const   PRODUCT_OUT_MOST_REQUEST = "PRODUCT_OUT_MOST_REQUEST";
export const   PRODUCT_OUT_MOST_REQUEST_SUCCESS = "PRODUCT_OUT_MOST_REQUEST_SUCCESS";

