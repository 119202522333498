export const TEMFILE_FETCH_LIST = "TEMFILE_FETCH_LIST";
export const TEMFILE_FETCH_LIST_SUCCESS = "TEMFILE_FETCH_LIST_SUCCESS";

export const TEMFILE_FETCH_ITEM_BY_ID = "  TEMFILE_FETCH_ITEM_BY_ID";
export const TEMFILE_FETCH_ITEM_BY_ID_SUCCESS =
  "  TEMFILE_FETCH_ITEM_BY_ID_SUCCESS";

export const TEMFILE_CREATE_UPDATE = " TEMFILE_CREATE_UPDATE";
export const TEMFILE_CREATE_UPDATE_SUCCESS = " TEMFILE_CREATE_UPDATE_SUCCESS";

export const TEMFILE_DELETE = "TEMFILE_DELETE";
export const TEMFILE_DELETE_SUCCESS = "TEMFILE_DELETE_SUCCESS";

export const TEMFILE_UPDATE = "TEMFILE_UPDATE";
export const TEMFILE_UPDATE_SUCCESS = "TEMFILE_UPDATE_SUCCESS";

export const TEMFILE_FETCH_BY_TYPE = " TEMFILE_FETCH_BY_TYPE";
export const TEMFILE_FETCH_BY_TYPE_SUCCESS = " TEMFILE_FETCH_BY_TYPE_SUCCESS";

export const TEMFILE_FETCH_autocomplete = " TEMFILE_FETCH_autocomplete";
export const TEMFILE_FETCH_autocomplete_SUCCESS =
  " TEMFILE_FETCH_autocomplete_SUCCESS";

export const TEMFILE_CHECK_EXISTS_VALUE = "TEMFILE_CHECK_EXISTS_VALUE";
export const TEMFILE_CHECK_EXISTS_VALUE_SUCCESS =
  "TEMFILE_CHECK_EXISTS_VALUE_SUCCESS";
