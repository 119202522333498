export const   PRODUCT_MATERIAL_FETCH_LIST = "PRODUCT_MATERIAL_FETCH_LIST";
export const   PRODUCT_MATERIAL_FETCH_LIST_SUCCESS = "PRODUCT_MATERIAL_FETCH_LIST_SUCCESS";

export const   PRODUCT_MATERIAL_FETCH_ITEM_BY_ID = "PRODUCT_MATERIAL_FETCH_ITEM_BY_ID";
export const   PRODUCT_MATERIAL_FETCH_ITEM_BY_ID_SUCCESS = "PRODUCT_MATERIAL_FETCH_ITEM_BY_ID_SUCCESS";

export const   PRODUCT_MATERIAL_CREATE_UPDATE = "PRODUCT_MATERIAL_CREATE_UPDATE";
export const   PRODUCT_MATERIAL_CREATE_UPDATE_SUCCESS = "PRODUCT_MATERIAL_CREATE_UPDATE_SUCCESS";

export const   PRODUCT_MATERIAL_DELETE = "PRODUCT_MATERIAL_DELETE";
export const   PRODUCT_MATERIAL_DELETE_SUCCESS = "PRODUCT_MATERIAL_DELETE_SUCCESS";

export const   PRODUCT_MATERIAL_UPDATE = "PRODUCT_MATERIAL_UPDATE";
export const   PRODUCT_MATERIAL_UPDATE_SUCCESS = "PRODUCT_MATERIAL_UPDATE_SUCCESS";

export const   PRODUCT_MATERIAL_FETCH_BY_TYPE = "PRODUCT_MATERIAL_FETCH_BY_TYPE";
export const   PRODUCT_MATERIAL_FETCH_BY_TYPE_SUCCESS = "PRODUCT_MATERIAL_FETCH_BY_TYPE_SUCCESS";

export const   PRODUCT_MATERIAL_FETCH_LIST_MOST = "PRODUCT_MATERIAL_FETCH_LIST_MOST";
export const   PRODUCT_MATERIAL_FETCH_LIST_MOST_SUCCESS = "PRODUCT_MATERIAL_FETCH_LIST_MOST_SUCCESS";

export const   PRODUCT_MATERIAL_FETCH_LIST_FULL = "PRODUCT_MATERIAL_FETCH_LIST_FULL";
export const   PRODUCT_MATERIAL_FETCH_LIST_FULL_SUCCESS = "PRODUCT_MATERIAL_FETCH_LIST_FULL_SUCCESS";

export const   PRODUCT_MATERIAL_MATERIAL_UPLOAD = "PRODUCT_MATERIAL_MATERIAL_UPLOAD";
export const   PRODUCT_MATERIAL_MATERIAL_UPLOAD_SUCCESS = "PRODUCT_MATERIAL_MATERIAL_UPLOAD_SUCCESS";

export const   PRODUCT_MATERIAL_FETCH_autocomplete = "PRODUCT_MATERIAL_FETCH_autocomplete";
export const   PRODUCT_MATERIAL_FETCH_autocomplete_SUCCESS = "PRODUCT_MATERIAL_FETCH_autocomplete_SUCCESS";
