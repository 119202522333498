import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { STATUS_CODE } from "../contants";
import * as conatant_type from "../contants/product_material";

import {
  handleFetchListSuccess,
  handleCreateUpdateSuccess,
  handleDeleteSuccess,
  handleFetchItemByIdSuccess,
  handleUpdateSuccess,
  handleFetchListMostSuccess,
  handleFetchListFullSuccess,
  handleUploadDataSuccess,
  handleAutocompleteteSuccess
} from "../actions/product_material";

import {
  fetchLists,
  fetchListsPost,
  handleDeleteApi,
  handleUpdateApi,
} from "../apis/common";
let url_router_default = "product-material/";

function* handleFetchListSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(fetchListsPost, url_router_default, requets, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchListSuccess(data));
  }
}

function* handleFetchListMostSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "most/",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchListMostSuccess(data));
  }
}
function* handleFetchListFullSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "full/",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchListFullSuccess(data));
  }
}

function* handleFetchItemByIdSaga({ payload }) {
  const { id, token } = payload;
  const resp = yield call(fetchLists, url_router_default + id, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    console.log(data);
    yield put(handleFetchItemByIdSuccess(data));
  }
}

function* handleCreateUpdateSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "create",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleCreateUpdateSuccess(data));
  }
}
function* handleDeleteSaga({ payload }) {
  const { id, token } = payload;
  const resp = yield call(handleDeleteApi, url_router_default, id, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleDeleteSuccess(data));
  }
}

function* handleUpdateItemSaga({ payload }) {
  const { requets, token } = payload;
  const { _id } = requets;
  let url = url_router_default + _id;
  const resp = yield call(handleUpdateApi, url, requets, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleUpdateSuccess(data));
  }
}
function* handleUploadSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(fetchListsPost, url_router_default + 'upload', requets, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
     console.log(data)
    yield put(handleUploadDataSuccess(data));
  }
}
function* handleautocompleteSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(fetchListsPost, url_router_default + 'autocomplete', requets, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
     yield put(handleAutocompleteteSuccess(data));
  }
}

function* handlePMaterialSaga() {
  yield takeLatest(
    conatant_type.PRODUCT_MATERIAL_FETCH_LIST,
    handleFetchListSaga
  );
  yield takeLatest(
    conatant_type.PRODUCT_MATERIAL_FETCH_LIST_MOST,
    handleFetchListMostSaga
  );
  yield takeLatest(
   conatant_type.PRODUCT_MATERIAL_FETCH_LIST_FULL,
   handleFetchListFullSaga
 );
  yield takeLatest(
    conatant_type.PRODUCT_MATERIAL_FETCH_ITEM_BY_ID,
    handleFetchItemByIdSaga
  );
  yield takeLatest(conatant_type.PRODUCT_MATERIAL_UPDATE, handleUpdateItemSaga);

  yield takeLatest(
    conatant_type.PRODUCT_MATERIAL_CREATE_UPDATE,
    handleCreateUpdateSaga
  );
  yield takeLatest(conatant_type.PRODUCT_MATERIAL_DELETE, handleDeleteSaga);
  yield takeLatest(conatant_type.PRODUCT_MATERIAL_MATERIAL_UPLOAD, handleUploadSaga);

  yield takeLatest(conatant_type.PRODUCT_MATERIAL_FETCH_autocomplete, handleautocompleteSaga);


}
export default handlePMaterialSaga;
