import * as conatant_types from "../contants/device";
import { toastError, toastSuccess } from "../helpers/toastHelpers";
const initialState = {
  data_table_device: [],
  data_pagination_device: 0,
  data_table_device_run: [],
  data_pagination_device_run: 0,
  data_create: null,
  initialValues: null,
  channels_by_device: [],
  data_group_by_time: null,
  list_data_error: [],
  list_group_data_error: [],
  data_setting_device: [],
  data_setting_device_pagination: 0,

  version_device: [],
  version_device_pagination:0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case conatant_types.DEVICE_FETCH_LIST_DATA_SETTING_SUCCESS: {
      const { data } = action.payload;
      console.log(data);
      return {
        ...state,
        data_setting_device: data.data,
        data_setting_device_pagination:
          data.count.length > 0 ? data.count[0].count : null,
      };
    }
    case conatant_types.DEVICE_FETCH_GROUP_BY_TIMESTAMP_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data_group_by_time: data,
      };
    }
    case conatant_types.DEVICE_FETCH_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        initialValues: null,
        data_table_device: data.data,
        data_pagination_device:
          data.count.length > 0 ? data.count[0].count : null,
      };
    }
    case conatant_types.DEVICE_FETCH_LIST_RUN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data_table_device_run: data.data,
        data_pagination_device_run:
          data.count.length > 0 ? data.count[0].count : null,
        channels_by_device: data.channels,
      };
    }
    case conatant_types.DEVICE_FETCH_ITEM_BY_ID_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        initialValues: data,
      };
    }
    case conatant_types.DEVICE_CREATE_UPDATE_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (!status) {
        toastError(data.message);
      } else {
        toastSuccess("Tạo thông tin thành công.");
        return { ...state, data_create: data };
      }
      return { ...state };
    }
    case conatant_types.DEVICE_DELETE_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (!status) {
        toastError(data.message);
      } else {
        const { resp } = data;
        toastSuccess("Đã xóa thành công.");
        let { data_table_device } = state;
        const index = data_table_device.findIndex(
          (item) => item._id == resp._id
        );
        data_table_device.splice(index, 1);
        return { ...state, data_table_device: [...data_table_device] };
      }
      return { ...state };
    }
    case conatant_types.DEVICE_UPDATE_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (!status) {
        toastError(data.message);
      } else {
        toastSuccess("Thay đổi thông tin thành công.");
      }
      return {
        ...state,
      };
    }
    case conatant_types.DEVICE_PAYMENT_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (!status) {
        toastError(data.message);
      } else {
        toastSuccess("Thay đổi thông tin thành công.");
        let { data_table_device } = state;
        let index = data_table_device.findIndex((x) => x._id == data.resp._id);
        if (index !== -1) {
          data_table_device[index].payment = true;
          return { ...state, data_table_device: [...data_table_device] };
        }
        return { ...state };
      }
      return {
        ...state,
      };
    }
    case conatant_types.DEVICE_FETCH_LIST_ERROR_SUCCESS: {
      const { data } = action.payload;
      return { ...state, list_data_error: data };
    }
    case conatant_types.DEVICE_FETCH_LIST_ERROR_BY_CHANNEL_SUCCESS: {
      const { data } = action.payload;
      return { ...state, list_group_data_error: data };
    }
    case conatant_types.DEVICE_ADD_NEW_VERSION_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (!status) {
        toastError(data.message);
      } else {
        toastSuccess("Đã thêm thành công.");
        window.location.reload();
      }
      return { ...state };
    }
    case conatant_types.DEVICE_GET_VERSION_BY_DEVICE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        version_device: data.data,
        version_device_pagination:
          data.count.length > 0 ? data.count[0].count : null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
