import { call, put, takeEvery } from 'redux-saga/effects';
import { STATUS_CODE } from '../contants';
import * as conatant_type from '../contants/user';

import {
   handleFetchListSuccess,
   handleCreateUpdateSuccess,
   handleDeleteSuccess,
   handleFetchItemByIdSuccess,
   handleUpdateSuccess,
   handleFetchListByRoleSuccess
} from '../actions/user';

import { fetchLists, fetchListsPost, handleDeleteApi, handleUpdateApi } from '../apis/common';

function* handleFetchListSaga({ payload }) {
   const { requets, token } = payload;
   const resp = yield call(fetchListsPost, 'user/', requets, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleFetchListSuccess(data));
   }
}
function* handleFetchListByRoleSaga({ payload }) {
   const { requets, token } = payload;
   const resp = yield call(fetchListsPost, 'user/list-by-role', requets, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleFetchListByRoleSuccess(data));
   }
}
function* handleFetchItemByIdSaga({ payload }) {
   const { id, token } = payload;
   const resp = yield call(fetchLists, 'user/' + id, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleFetchItemByIdSuccess(data));
   }
}

function* handleCreateUpdateSaga({ payload }) {
   const { requets, token } = payload;
   const resp = yield call(fetchListsPost, 'user/create', requets, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleCreateUpdateSuccess(data));
   }
}
function* handleDeleteSaga({ payload }) {
   const { id, token } = payload;
   const resp = yield call(handleDeleteApi, 'user/', id, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleDeleteSuccess(data));
   }
}
function* handleUpdateItemSaga({ payload }) {
   const { requets, token } = payload;
   const { _id } = requets;
   let url = "user/" + _id;
   const resp = yield call(handleUpdateApi, url, requets, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleUpdateSuccess(data));
   }
}

function* handleAuthen() {
   yield takeEvery(conatant_type.USER_FETCH_LIST, handleFetchListSaga);
   yield takeEvery(conatant_type.USER_FETCH_LIST_BY_ROLE, handleFetchListByRoleSaga);

   yield takeEvery(conatant_type.USER_FETCH_ITEM_BY_ID, handleFetchItemByIdSaga);

   yield takeEvery(conatant_type.USER_CREATE_UPDATE, handleCreateUpdateSaga);
   yield takeEvery(conatant_type.USER_DELETE_ACCOUNT, handleDeleteSaga);
   yield takeEvery(conatant_type.USER_UPDATE_ACCOUNT, handleUpdateItemSaga);


}

export default handleAuthen;